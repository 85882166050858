import React, { useState, useEffect } from 'react';

const TimeoutBar = ({ totalTime, onTimeout }) => {
  const [timeLeft, setTimeLeft] = useState(totalTime);

  useEffect(() => {
    if (timeLeft <= 0) {
      onTimeout();
      return;
    }

    const intervalId = setInterval(() => {
      setTimeLeft((prev) => Math.max(0, prev - 100));
    }, 100);

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, [timeLeft, onTimeout]);

  const progress = (timeLeft / totalTime) * 100;

  return (
    <div style={{ width: '100%', height: '15px', background: '#ddd', borderRadius: '7px', overflow: 'hidden' }}>
      <div
        style={{
          width: `${progress}%`,
          height: '100%',
          background: 'var(--color-green)',
          transition: 'width 0.1s linear, background 0.1s ease',
        }}
      />
    </div>
  );
};

export default TimeoutBar;