import './AnnualUpgrade.scss';

import React, { useState, useEffect } from 'react'
import Modal from '../../features/keywords/react/Modal/Modal';
import sharedCookies from '../../shared/sharedCookies';
import { getLimitsForTier, getMonthlyForTier, getPlanByPriceId } from '../../shared/plans.js';
import { switchSubscription } from './backend.js';
import classNames from 'classnames';
import TimeoutBar from './TimeoutBar.js';

export default function AnnualUpgrade({user, countryCode}) {
  
  const [Shown, SetShown] = useState(false);
  const [Stage, SetStage] = useState('one');
  const [StageVisible, SetStageVisible] = useState('one');
  const [OldPrice, SetOldPrice] = useState(null);
  const [CurrentPrice, SetCurrentPrice] = useState(null);
  const [TimeInMilliseconds, SetTimeInMilliseconds] = useState(120000);
  const [NewPrice, SetNewPrice] = useState(null);
  const [Processing, SetProcessing] = useState(false);

  useEffect(() => {
    const seenBefore = sharedCookies.get('shownAnnualUpgrade');
    // const seenBefore = false;
    const { subscription } = user;

    if (seenBefore || !subscription) return;
    if (subscription.state != 'active') return;
    if (subscription.period != 'monthly') return;
    if (subscription.tier != 'bronze') return;
    if (user.upcoming_price_id) {
      const upcomingSubscription = getPlanByPriceId(user.upcoming_price_id)
      if (upcomingSubscription.period === 'annual') return;
    }

    let currentPrice;
    try {
      currentPrice = getPlanByPriceId(subscription.plan_code);
      if (currentPrice.is_trial_plan) currentPrice = getMonthlyForTier(subscription.tier);
    } catch(e) {
      return; // Price is a legacy price so don't show the offer.
    }


    let oldPrice;
    let newPrice;
    if (currentPrice.tier === 'bronze') {
      oldPrice = getPlanByPriceId('silver_annual');
      newPrice = getPlanByPriceId('silver_annual_for_price_of_bronze');
    }
    if (currentPrice.tier === 'silver') {
      oldPrice = getPlanByPriceId('gold_annual');
      newPrice = getPlanByPriceId('gold_annual_for_price_of_bronze');
    }

    newPrice.amount_per_month = (newPrice.unit_amount_in_cents / 12 / 100).toFixed(2);
    newPrice.amount_per_year = (newPrice.unit_amount_in_cents / 100).toFixed(0);
    newPrice.saving = (oldPrice.unit_amount_in_cents / 100 - newPrice.unit_amount_in_cents / 100).toFixed(0);
    (oldPrice.unit_amount_in_cents / 100 - newPrice.unit_amount_in_cents / 100).toFixed(0);
    oldPrice.amount_per_month = (oldPrice.unit_amount_in_cents / 100).toFixed(0);
    oldPrice.amount_per_year = (oldPrice.unit_amount_in_cents / 100).toFixed(0);
    newPrice.saving_per_cent =  ((1 - newPrice.amount_per_year / oldPrice.amount_per_year) * 100).toFixed(0);

    currentPrice.limits = getLimitsForTier(currentPrice.tier);
    newPrice.limits = getLimitsForTier(newPrice.tier);

    SetOldPrice(oldPrice);
    SetCurrentPrice(currentPrice);
    SetNewPrice(newPrice);

    setTimeout(() => {
      sharedCookies.set('shownAnnualUpgrade', 'true');
      openModal();
    }, 0);
  }, []);

  const openModal = () => {
    SetShown(true);
  }

  const closeModal = () => {
    SetShown(false);
  }

  const goToStage = (stage) => {
    SetStageVisible('');
    setTimeout(() => {
      if (stage == 'two') {
        SetTimeInMilliseconds(120000);
      }
      SetStage(stage);
      SetStageVisible(stage);
    }, 500);
  }

  const upgrade = () => {
    SetProcessing(true);
    switchSubscription({plan_code: NewPrice.id})
      .then(_response => {
        goToStage('done');
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch(console.error)
      .finally(() => {
        SetProcessing(false);
      });
  }

  const handleTimeout = () => {
    if (!Processing && Stage != 'done') {
      closeModal();
    }
  }

  const captialize = str => str.charAt(0).toUpperCase() + str.slice(1);

  return (
    <Modal show={Shown} dismissable={false} showClose={false} onClose={closeModal}>
      {NewPrice && <div id='annual-upgrade' style={{padding: '30px 20px 0'}}>
        {Stage != 'done' && <>
          <p style={{margin: '0 0 20px'}}>
            <strong>
            <em>
              Warning! You won't see this offer again...
            </em></strong>
          </p>
          <TimeoutBar key={Stage} totalTime={TimeInMilliseconds} onTimeout={handleTimeout} />
        </>}
        
        {/* STAGE 1 */}
        {Stage === 'one' && <div className={classNames({stage: true, visible: StageVisible === 'one'})}>
          <p className='text-special-offer' style={{padding: '0 50px', margin: '30px 0 50px'}}>Hold on! You can convert your subscription to an annual plan and save ${NewPrice.saving}!</p>
          <p className='tier-explainer' style={{color: 'var(--color-text-standard)'}}>
            You're currently on the <strong className={CurrentPrice.tier}>{captialize(CurrentPrice.tier)}</strong> one month trial.<br/>
            For one time only we'll offer you a full year<br/> of <strong className={NewPrice.tier}>{captialize(NewPrice.tier)}</strong> <span style={{color: '#fff'}}>(worth ${OldPrice.amount_per_year})</span> for just</p>
          <p style={{margin: '30px 0 10px'}}>
            <span style={{textDecoration: 'line-through', fontSize: '50px', color: '#1f1f1fb3', display: 'inline-block', marginRight: '10px'}}><strong>${OldPrice.amount_per_year}</strong></span>
            <span style={{fontSize: '50px', color: '#fff'}}><strong>${NewPrice.amount_per_year}</strong></span>
          </p>

          <div style={{display: 'flex', justifyContent: 'space-between', margin: '30px 0 30px'}}>
            <button style={{width: 'calc(50% - 10px)'}} className='standard dismiss-button' onClick={closeModal}>No thanks</button>
            <button style={{width: 'calc(50% - 10px)', fontFamily: 'Proxima Nova Bold'}} className='accented-standard proceed-button' onClick={() => goToStage('two')}>Tell me more...</button>
          </div>
          <p style={{color: '#384671'}}>
            <em>(That's a huge saving of {NewPrice.saving_per_cent}%)</em>
          </p>
        </div>}
        
        
        {/* STAGE 2 */}
        {Stage === 'two' && <div className={classNames({stage: true, visible: StageVisible === 'two'})}>
          <p style={{padding: '0 30px'}}>
            This is your one chance to secure a whole year of Wordtracker at a huge discount, ensuring you get maximum value and unbeatable savings.
          </p>
          <p style={{margin: '30px 0 40px'}}>
            <span style={{textDecoration: 'line-through', fontSize: '40px', color: 'var(--color-silver)', display: 'inline-block', marginRight: '10px'}}>${OldPrice.amount_per_year}</span>
            <span style={{fontSize: '50px'}}><strong>${NewPrice.amount_per_year}</strong></span>
          </p>
          <div className='benefits'>
            <p><strong>What you get:</strong></p>
            <ul>
              <li>
                <s>{captialize(CurrentPrice.tier)}</s>
                <span class='after-benefit'><i class="fa-solid fa-check"></i>{captialize(NewPrice.tier)}</span>
              </li>
              <li>
                <s>Month</s>
                <span class='after-benefit'><i class="fa-solid fa-check"></i>Yearly subscription</span>
              </li>
              <li>
                <s>{CurrentPrice.limits.countries}</s> <span class='after-benefit'><i class="fa-solid fa-check"></i>{NewPrice.limits.countries} countries</span>
              </li>
              <li>
                {CurrentPrice.tier === 'bronze' && <>
                  <s>{CurrentPrice.limits.ranking_lists}</s> <span class='after-benefit'><i class="fa-solid fa-check"></i>{NewPrice.limits.ranking_lists} ranking lists</span>
                </>}
                {CurrentPrice.tier === 'silver' && <>
                  <s>{CurrentPrice.limits.results}</s> <span class='after-benefit'><i class="fa-solid fa-check"></i>{NewPrice.limits.results} keyword results</span>
                </>}
              </li>
              <li>
                <s>{CurrentPrice.limits.competitors}</s> <span class='after-benefit'><i class="fa-solid fa-check"></i>{NewPrice.limits.competitors} domain competitors</span>
              </li>
              <li>
                <s>{CurrentPrice.limits.ranking_keywords * CurrentPrice.limits.ranking_lists}</s> <span class='after-benefit'><i class="fa-solid fa-check"></i>{NewPrice.limits.ranking_keywords * NewPrice.limits.ranking_lists} ranking keywords</span>
              </li>
            </ul>
          </div>
          <div style={{margin: '30px 0 10px'}}>
            <button disabled={Processing} style={{width: '300px', fontFamily: 'Proxima Nova Bold'}} className='accented-standard proceed-button' onClick={upgrade}>{Processing ? 'Processing...' : `Upgrade now for just $${NewPrice.amount_per_year}`}</button>
            <button style={{width: '180px', border: 0, marginTop: '15px'}} className='standard' onClick={closeModal}>I'm not interested</button>
          </div>
        </div>}
        {Stage === 'done' && <div style={{paddingBottom: '40px'}} className={classNames({stage: true, done: true, 'signup-form-area': true, visible: StageVisible === 'done'})}>
          <div className='big-tick'></div>
          <h2>Upgrade complete!</h2>
          Thank you. One moment while we refresh your account status...
        </div>}
      </div>}
    </Modal>
  )
}
