import React, { useLayoutEffect, useEffect, useState, useRef } from 'react'
import { CSSTransition } from 'react-transition-group'
import classNames from 'classnames'
// import { Button, IconButton } from '@material-ui/core'
// import { Close } from '@material-ui/icons';
import * as defs from './Modal.module.scss'
// import useLockBodyScroll from '../useLockBodyScroll.hook'
// import { disableBodyScroll, enableBodyScroll } from '../../utils/bodyScrollLock'

const Modal = props => {

  const [shown, setShown] = useState(false);
  const [EventElement, SetEventElement] = useState(null);

  // useLayoutEffect(() => {
  //   disableBodyScroll(nodeRef)
  //   setShown(true)
  //   return () => {
  //     enableBodyScroll(nodeRef)
  //     setShown(false)
  //   };
  // }, [])
  
  const {
    className: passedClasses = null,
    children,
    dismissable = true,
    showClose = true,
    onClose,
    show
  } = props


  useEffect(() => {
    if (show) setShown(true);
    if (!show) setShown(false);
  }, [show]);

  const handleClick = e => {
    switch(e.type) {
      case 'mousedown':
        return SetEventElement(e.target);
      case 'mouseup':
        if (dismissable && e.target.className === defs.modal && e.target === EventElement) {
          return onClose();
        }
      default: break;
    }
  }

  const baseClasses = { [passedClasses]: !!passedClasses }
  const modalClasses = classNames({...baseClasses, [defs.modal]: true})
  const backdropClasses = classNames({...baseClasses, [defs.backdrop]: true})
  const contentClasses = classNames({...baseClasses, [defs.content]: true})

  // const nodeRef = useRef(null)

  return (
    <CSSTransition in={shown} key='modal-transition' appear unmountOnExit timeout={{enter: 200, exit: 150}} className='modal-popup'> 
      <div>
        <div className={backdropClasses} />
        <div className={modalClasses} onMouseDown={handleClick} onMouseUp={handleClick} >
          <div style={{padding: '8px 22px 22px', position: 'relative'}} className={contentClasses}>
            {showClose && <button className='dismiss-icon-modal' onClick={onClose} style={{padding: '3px', right: '22px', top: '24px', zIndex: '2', position: 'absolute'}}>Close</button>}
            {children}
          </div>
        </div> 
      </div>
    </CSSTransition>
  )

}

export default Modal