import { sendTriggerUpsellEvent } from '../shared/events';
import './expiredBanner.scss';

class _Controller {
  
  constructor(UserService) {

    this.user = UserService.user;

    this.shown = !!this.user.isExpiredUser;

  }

  upgrade(e) {
    e.preventDefault();
    sendTriggerUpsellEvent();
  }

}

_Controller.$inject = ['UserService'];

export const expiredBanner = {
  controller: _Controller,
  template: `
    <div ng-if='$ctrl.shown' id="expired-banner">
      <p>Your subscription has expired. <a ng-click='$ctrl.upgrade($event)'>Subcribe to keep searching.</p>
    </div>`
}